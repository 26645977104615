import styles from "./loading_spinner.css";
import classNames from "classnames";

interface LoadingSpinnerProps {
  size?: "small" | "medium" | "large";
  color?: "white" | "green" | "red";
}

export const links = () => [{ rel: "stylesheet", href: styles }];

export const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const { size = "large", color = "white" } = props;

  const className = classNames({
    "loading-spinner": true,
    [`loading-spinner--${size}`]: true,
    [`loading-spinner--${color}`]: true
  });

  return <div className={className}></div>;
};